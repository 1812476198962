body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    width: 100%;
    height: 100%;
  }
}

@font-face {
  font-family: 'YouSheBiaoTiHei'; //重命名字体名
  src: url('../font/title.ttf'); //引入字体
  font-weight: normal;
  font-style: normal;
}

.head-search-view {
  position: relative;
  width: 100%;
  min-height: 50px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  padding: 5px 10px 5px 10px;
  //margin: 0;
  //padding: 15px 10px 5px 10px;
  margin: 0 0 -5px 0;

  .search-view {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    .ant-input-affix-wrapper, .ant-select-selector {
      border-radius: 2px;
    }

    //.ant-select-selector {
    //  min-width: 200px;
    //}

    .ant-row {
      margin-bottom: 15px;
    }

    .add-button {
      margin-right: 15px;
    }
  }


  .children-view {
    display: flex;
    justify-content: flex-start;
    align-content: center;

    .ant-btn {
      margin-right: 12px;
    }
  }
}

.columns-table-action {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .ant-btn-link {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.ellipsis-style {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.user-modal-datepicker{
  left: auto !important;
  right: 15px !important;
}

