.info-box{
  width: 800px;
  display: flex;
  justify-content: space-between;
  //border-top: 1px solid rgba(253, 253, 253, 0.12);
  //border-left: 1px solid rgba(253, 253, 253, 0.12);
  border: 1px solid rgba(253, 253, 253, 0.12);
  border-radius: 5px;
  .info-table-left{
    color: rgba(255, 255, 255, 0.65);
    background-color: rgba(255, 255, 255, 0.04);
    flex: 1;
    text-align: center;
    border-right: 1px solid rgba(253, 253, 253, 0.12);
  }
  .info-table-right{
    flex: 1;
    text-align: center;
  }
  .table-item{
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgba(253, 253, 253, 0.12);
    //border-right: 1px solid rgba(253, 253, 253, 0.12);
  }

}