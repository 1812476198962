.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  fill: currentColor;
  color: #fff;
}

.hamburger.is-active {
  transform: rotate(180deg);
}
.effective{
  .ant-btn-default{
    // display: none;
    background-color: #1677ff !important;
    &:hover {
      background-color: #4893fd !important;
    }
  }
  .ant-btn-primary{
    display: none;
  }
}

