.preview{
    width: 100%;
    height: 100%;
    // padding: 10px;
    .title{
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        text-indent: 2rem;
        background-color: #dddcdc;
    }
    .content{
        // padding-top: 20px;
    }
}
.map-wrap{
    height: calc(100% - 28px);
    margin-top: 10px;
    .search-wrap{
  
    }
    .map-content{
      width: 100%;
      height: 100%;
      .amap-logo {
        display: none!important;
        visibility: hidden!important;
      }
  
      .amap-copyright {
        display: none!important;
        visibility: hidden!important;
      }
    }
    .info-content{
      padding-top: 10px;
      .address-info{
        display: flex;
        .val{
          flex: 1;
          word-break: break-all;
        }
      }
    }
  }
// 输入框label宽度
.ant-form-item-label{
    width: 76px;
}
.ant-form-item-control-input-content{
    // display: flex;
    .ant-form-item{
        margin-bottom: 0;
    }  
}
// 文本框字数提示
.ant-input-data-count{
    display: none;
    // color: rgb(31, 29, 29);
}
// 文本框
.ant-input{
    padding-right: 12px;
}
.ant-input::-webkit-scrollbar {
    width: 2px;
}
.xmlH{
  width: 100%;
  height: 99%;
}