.project-select-content{
  margin-top: 20px;
  .search-wrap{

  }
  .project-list-wrap{
    margin-top: 16px;
    max-height: 400px;
    overflow-y: auto;
    .project-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
      .project-name{
        font-size: 14px;
        color: #EBF5FF;
      }
      .navigate-icon{
        img{
          width: 24px;
          height: 24px;
          cursor: pointer;
        }

      }
    }
  }
}
