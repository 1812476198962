@import '~antd/dist/reset.css';

//主题定制
.ant-table-content {
  .ant-table-thead {
    .ant-table-cell::before {
      width: 0 !important;
    }
  }

  .ant-table-thead > tr > th, .ant-table-tbody > .ant-table-row > td {
    padding: 8px !important;
  }
}

::-webkit-scrollbar {
  background-color: transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #0878df;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

//.ant-table-thead > tr > th{
//  background: #F4FAFE;
//}

.ant-table-row:nth-child(odd) {
  background: #12181E;
  //border: 1px solid #F4FAFE;
}

.ant-table-row:nth-child(odd) > td {
  background: #12181E;
  //border: 1px solid #F4FAFE;
}

.ant-modal-content {
  background-color: #2C313B !important;

  .ant-modal-confirm-title, .ant-modal-confirm-content {
    color: #ffffff !important;
  }

  .ant-modal-confirm-btns {
    .ant-btn-default {
      background-color: #3A4252;
      border-color: #316B82;
      box-shadow: 0 2px 0 rgb(255 255 255 / 4%);
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.ant-modal-footer {
  text-align: center !important;

  .ant-btn {
    width: 100px;
  }

  .ant-btn:first-child {
    margin-right: 20px;
    width: 100px;
  }
}

.ant-modal-content, .ant-drawer-content {
  .ant-form-inline .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-tabs-tab {
    min-width: 130px;
    justify-content: center;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav:before {
    border: none;
  }

}

.ant-drawer .ant-drawer-body {
  padding: 18px;
}

.ant-image .ant-image-mask .ant-image-mask-info {
  visibility: hidden;
}

.head-search-treeSelect {
  .ant-select-tree-list {
    overflow-x: scroll;

    .ant-select-tree-list-holder {
      width: 150%;

      .ant-select-tree-title {
        width: 80px;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }
    }

    .ant-select-tree-list-scrollbar-thumb{
      visibility: hidden;
    }
  }
}

input:-webkit-autofill, //谷歌自动填充背景为白色问题
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition-delay: 99999s;
  transition: color 99999s ease-out, background-color 99999s ease-out;
  -webkit-transition-delay: 99999s;
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
  // -webkit-text-fill-color: #807c7c;
}

.ant-cascader-dropdown .ant-cascader-menus{//级联选择限制宽度，添加滚动条
  width: 220px;
  overflow-y: auto;
}
