.wxBox{
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    .wxImg{
        border-radius: 50%;
        overflow: hidden;
    }
}