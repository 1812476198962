.login-wrap{
  position: relative;
  background-color: #141622;
  height: 100vh;

  .head{
    background: url("../../../assets/images/login/TopBG.png");
    background-size: 100% 100%;
    width: 100%;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 15px;
    //padding: 5px;
    //box-sizing: border-box;
    line-height: 60px;
  }
  .login-content{
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/login/BG.png") 0 0 ;
    background-size: 100% 100%;
    position: relative;
    .login-card{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      align-items: center;
      .left-bg{
        background: url("../../../assets/images/login/Login_left.png");
        background-size: 100% 100%;
        width: 122px;
        height: 480px;
      }
      .center-bg{
        width: 500px;
        height: 330px;
        background: rgba(19,21,24,0.7);
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5), inset 0px 16px 48px 0px rgba(93,173,255,0.15), inset 0px 4px 48px 0px rgba(53,76,147,0.5), inset 0px 1px 4px 0px #2E63DC;
        //border: 1px solid;
        border-image: linear-gradient(180deg, rgba(52, 223, 255, 1), rgba(24, 113, 214, 0)) 1 1;
        backdrop-filter: blur(4px);
        &-wrap{
          width: 100%;
          height: 100%;
          background: url("../../../assets/images/login/Login.png");
          background-size: 100% 100%;
          position: relative;
        }
        .title{
          font-size: 15px;
          font-weight: bold;
          color: #FFFFF6;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 7px;
        }

        .login-form{
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%,-50%);
          .ant-form{
            .ant-input-affix-wrapper{
              width: 280px;
              border-radius: 2px;
              border: 1px solid #305F67;
            }
          }
          .ant-form-item:last-child{
            margin-bottom: 0;
          }
          .ant-input-affix-wrapper{
            border: none;
          }
          input{
            color: #fff;
            &::placeholder{
              opacity: 0.5;
              font-size: 12px;
              color: #badfee;
              font-weight: 400;
            }
          }
          .ant-checkbox-wrapper{
            .ant-checkbox{
              width: 16px;
              height: 16px;
              .ant-checkbox-inner{
                background: none;
                border: none;
                background: #132343;
                border-radius: 2px;
                border: 1px solid #1EB9FA;
              }
            }
          }
          .login-rule{
            font-size: 14px;
            color: #BADFEE;
            margin-left: 5px;
            span{
              color: #1EB9FA;
              cursor: pointer;
            }
          }
          button{
            width: 100%;
            height: 48px;
            background: #08A4E6;
            color: #FFFFF6;
          }
        }

      }
      .right-bg{
        background: url("../../../assets/images/login/Login_right.png");
        background-size: 100% 100%;
        width: 122px;
        height: 480px;
      }
    }
    .bottom-bg{
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      .line-bg{
        background-image: linear-gradient(270deg, rgba(11, 112, 255, 0) 0%, #8cf2ff 50%, rgba(11, 112, 255, 0) 100%);
        width: 50%;
        margin: 0 auto;
        height: 0.5px;
      }
      .system-copy{
        background: url("../../../assets/images/login/footerBG.png");
        background-size: 100% 100%;
        width: 100%;
        height:120px ;
        width: 100%;
        color: #4A596E;
        text-align: center;
        line-height: 120px;
      }
    }

  }
}
