.self-tabs{
  .tab-wrap{
    width: 100%;
    height: 48px;
    background-color: #0C121A;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    padding-left: 40px;
    box-sizing: border-box;
    .tab-item{
      width: auto;
      padding: 13px 19px;
      height: 40px;
      border-radius: 4px 4px 0px 0px;
      flex-direction: column;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      &-content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      &-active{
        background-color: #1A232C;
      }
      &-line{
        width: calc(100% - 5px);
        height: 2px;
        background: #1890FF;
        border-radius: 1px;
        margin-top: 4px;
      }
    }
  }
}

