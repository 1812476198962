.personal-add-service{
  display: flex;
  width: 100%;
  &-menu{
    width: 200px;
    border: 1px solid #000;
    padding: 10px;
    box-sizing: border-box;
    .menu-item{
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      &-active{
        background-color: #0c75ff;
      }
    }
  }
  &-table{
    flex: 1;
    margin-left: 10px;
  }
}
