.layout-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .ant-layout-header {
    background: #0c121a;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 50px;

    .ant-breadcrumb-link {
      color: rgba(255, 255, 255, 0.68);
    }

    .ant-breadcrumb-separator {
      color: rgba(255, 255, 255, 0.68);
    }

    li:last-child {
      .ant-breadcrumb-link {
        color: #ffffff;
      }
    }
  }

  .ant-layout-sider::-webkit-scrollbar {
    display: none
  }

  .ant-layout-sider {
    background-color: #0c121a;
    overflow: auto;
    height: calc(100vh - 44px);
    left: 0;
    top: 0;
    bottom: 48px;

    .ant-menu-dark {
      background: transparent;
      //font-size: 14px;

    }

    .ant-menu-item {
      transform: translateX(10px);

      .ant-menu-item-icon {
        transform: translateX(-10px);
      }

      .ant-menu-title-content {
        transform: translateX(-10px);
      }
    }

    .ant-menu-item-selected, .ant-menu-item, .ant-menu-submenu-title {
      height: 44px;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .ant-menu-item-selected {
      background: #1A232C;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .sider-collapsed {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      background: #000C17;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0;

      .collapsed-icon {
        color: #ffffff;
      }
    }
  }

  :where(.css-dev-only-do-not-override-1os3dla).ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: none;
  }

  .layout-logo {
    height: 77px;
    //width: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-img {
      width: 32px;
      height: 32px;
    }

    .logo-text {
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      animation: fadeIn 0.618s ease-in-out;
      animation-fill-mode: forwards;
      font-family: YouSheBiaoTiHei;
      margin: 0 15px;
    }
  }

  .layout-logo:hover {
    filter: brightness(0.86);
    cursor: pointer;
  }

  .title-user {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .current-project{
      display: flex;
      align-items: center;
      padding-right:20px;
      .project-label{
        padding-right: 5px;
      }
    }
    .user-content {
      line-height: normal;
      margin-right: 10px;
      text-align: left;

      .user-company {
        font-size: 12px;
        font-weight: 600;
        color: #FFFFFF;
      }

      .user-name {
        font-size: 12px;
        font-weight: normal;
        color: #7F828A;
      }
    }

    .user-action {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      background: #3A4252;
      border-radius: 2px;
      border: none;
      padding: 0;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .ant-layout-header {
    padding: 0 10px
  }

  .ant-layout-sider-collapsed {
    .layout-logo {
      padding: 0;
    }

    .logo-text {
      display: none;
    }
  }

  .layout-content {
    //padding: 24px 32px 0px;
    padding: 10px 15px;
    overflow: auto;
    background-color: #1A232C;
    border-radius: 10px;
    color: #FFFFFF;
    margin: 0 10px 10px 0;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.5);
  }

  .layout-content::-webkit-scrollbar {
    background-color: transparent;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    width: 7px;
    height: 7px;
  }

  .layout-content::-webkit-scrollbar-thumb {
    background-color: #0878df;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
