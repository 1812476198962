.associated-box {
  height: 100%;
  overflow-y: auto;

  .box-item {
    width: 400px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    line-height: 30px;

    .item-label {
      width: 100px;

      // .projectName {
      //   color: red;
      // }
    }

    .item-value {
      flex: 1;
      padding-left: 30px
    }
  }
}